'use strict';

Gri.module({
  name: 'content-alpha',
  ieVersion: null,
  $el: $('.content-alpha'),
  container: '.content-alpha',
  chain: 'modal-beta',
  fn: function () {

    // region variables
    const $this = this.$el;
    let $contentInner = $this.find('.content-alpha-inner');
    let $contentContent = $this.find('.content-alpha-content');
    let $contentPerson = $this.find('.content-alpha-person');
    // endregion

    // region run
    resizeController();
    $(window).on('resize', resizeController);

    if($contentPerson.length > 0) {
      $.ajax({
        url: '/person.json',
        success: function (data) {
          // Json Data Append Start
          let _tmp = '';
          $contentPerson.empty();
          $.each(data, function (index, val) {
            _tmp += `<div class="list-alpha clearfix">
                <div class="image-wrapper col-xs-5 col-md-4">
                    <img src="${val.image}">
                </div>
                <div class="text-wrapper col-xs-7 col-md-8">
                  <h3>${val.name}</h3>
                  <h5>${val.title}</h5>
                  <a href="javascript:;" data-index="${index}" class="button-alpha">DETAY</a>
                </div>
              </div>`;
          });
          $contentPerson.append(_tmp);
          // Json Data Append Finish

          // Button Click Listener for Modal Start
          let $listButton = $contentPerson.find('.button-alpha');
          let _modalTmp = '';
          $listButton.on('click', function () {
            let thisDataID = $(this).data('index');
            $('[tpl="modal-content"]').find('.content').empty();
            _modalTmp = ` <h3>${data[thisDataID].name}</h3>
                <h5>${data[thisDataID].title}</h5>
                <p>${data[thisDataID].desc}</p>`;
            $('[tpl="modal-content"]').find('.content').append(_modalTmp);
            $(window).trigger('modal-content');
          });
          // Button Click Listener for Modal Finish
        },
        error: function () {
          console.log('Person list not found!');
        }
      })
    }
    // endregion

    // region events
    if(!$contentInner.hasClass('lock') && $contentContent.outerHeight() > $contentInner.outerHeight()) {
      $contentInner.bind('mousewheel DOMMouseScroll', function(e) {
        let scrollTo = null;
        if (e.type == 'mousewheel') {
          //scrollTo = (e.originalEvent.wheelDelta * -1);
        }
        else if (e.type == 'DOMMouseScroll') {
          //scrollTo = 40 * e.originalEvent.detail;
        }
        if (scrollTo) {
          //e.preventDefault();
          //$(this).scrollTop(scrollTo + $(this).scrollTop());
        }
      });
    }
    // endregion

    // region events functions

    // endregion

    // region functions
    function resizeController() {
      let windowH = $(window).height();
      let windowW = $(window).width();
      let headerH = $('.header-alpha').outerHeight();
      $this.outerHeight(windowH - headerH);
      if(windowW < 992) {
        $this.addClass('auto-height');
      } else {
        $this.removeClass('auto-height');
      }
    }
    // endregion

  }
});
